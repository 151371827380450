<template>
  <div v-if="displayValue">
    <ReportCompareAnimation
      v-if="!isMobile"
      :prospectScore="displayValue.score"
      :averageScore="displayValue.averageScore"
      :age="displayValue.age"
      :gender="displayValue.gender"
      :pdfType="pdfType"
    />

    <ReportCompareAnimationMobile
      v-if="isMobile"
      :prospectScore="displayValue.score"
      :averageScore="displayValue.averageScore"
      :age="displayValue.age"
      :gender="displayValue.gender"
      :pdfType="pdfType"
    />
  </div>
</template>

<script>
import ReportCompareAnimation from "@/components/ReportCompareAnimation.vue";
import ReportCompareAnimationMobile from "@/components/ReportCompareAnimationMobile.vue";

export default {
  components: {
    ReportCompareAnimation,
    ReportCompareAnimationMobile,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isEditing: Boolean,
    isMobile: Boolean,
    pdfType: String,
  },
  data() {
    return {
      displayValue: null,
      serverValue: {
        score: "{{score}}",
        averageScore: "{{average_score_for_age}}",
        age: "{{current_age}}",
        gender: "{{gender}}",
      },
    };
  },
  mounted() {
    // if isEditing, set with mock data and emit serverValue
    // else, set to value passed in

    if (this.isEditing) {
      this.displayValue = {
        score: 65,
        averageScore: 25,
        age: 45,
        gender: "Female",
      };
      this.$emit("update:value", Object.assign({}, this.serverValue));
    } else {
      this.displayValue = this.value;
    }
  },
};
</script>
